.dot {
  cursor: pointer;
  background-color: #ddd;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 4px;
  display: inline-block;
}

.dot:hover {
  background-color: #a48e45;
}

@media (hover: none) {
  .dot:hover {
    background-color: #ddd;
  }
}

.dot.activeDot {
  background-color: #a48e45;
}
