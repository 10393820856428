@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

:local .animationRunning {
  animation-name: placeHolderShimmer;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

:local .background {
  background: linear-gradient(to right, #f4f4f4 0%, #e2e2e2 50% 51%, #f4f4f4 100%) 0 0 / 800px 100px;
}

:local .lazyLoadWrapper {
  position: relative;
  top: 0;
  left: 0;
}

:local .placeholderHidden {
  visibility: hidden;
  opacity: 0;
}

:local .placeholderPositioning {
  z-index: 2;
  position: absolute;
}

:local .placeholderTransition {
  transition: visibility .15s ease-out, opacity .15s ease-out;
}

:local .placeholder {
  width: 100%;
  height: 100%;
}

:local .child {
  z-index: 1;
  position: relative;
}
