:local .dotsWrapper {
  margin: auto;
  overflow: hidden;
  height: 16px;
}
:local .wrapper {
  display: flex;
  align-items: center;
  transform: translate3d(0, 0, 0);
}
:local .dot {
  min-width: 9px;
  min-height: 9px;
  display: flex;
  visibility: hidden;
  align-items: center;
  margin: 0 4.5px;
  cursor: pointer;
}
:local .dot.isVisible {
  visibility: visible;
}
:local .dot:after {
  min-width: 9px;
  min-height: 9px;
  content: "";
  display: block;
  background: #ddd;
  border-radius: 50%;
}
:local .dot.small:after {
  min-width: 7px;
  min-height: 7px;
}
:local .dot.tiny:after {
  min-width: 5px;
  min-height: 5px;
}
:local .dot:hover:after, :local .dot.isCurrent:after {
  background: #a48e45;
}
:local .dot.isCurrentDark:after {
  background: #222;
}