:local .badgeIcon {
  width: 12px;
  height: 10px;
  margin: 0 6px 2px;
}

:local .badgeText {
  color: #888;
  margin-right: 6px;
}
