:local .wrapper {
  overflow: hidden;
}
:local .relative {
  position: relative;
}
:local .list {
  display: flex;
  overflow: visible;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
}
:local .list.itemsTopAlign {
  align-items: flex-start;
}
:local .item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 10px;
}
:local .arrowWrapper {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 100%;
  z-index: 10;
}
:local .prevArrowWrapper {
  left: 9px;
}
:local .nextArrowWrapper {
  right: 9px;
}
:local .arrow {
  fill: #888;
  width: 24px;
  height: 24px;
}
:local .prevArrow {
  margin-right: 4px;
}
:local .nextArrow {
  margin-left: 4px;
}