.itemWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.itemWrapper.xSmall {
  width: 100%;
}

.itemWrapper.xSmall.swiperItem {
  background-color: #fff;
  padding: 0 9px;
}

.itemWrapper.small {
  width: 100%;
}

.itemInner {
  width: 100%;
  position: relative;
}

.itemInner.xSmall {
  max-width: 148px;
  height: 148px;
  margin: 9px 27px 0 0;
}

@media (width <= 768px) {
  .itemInner.xSmall {
    max-width: 120px;
    height: 120px;
    margin: 0 45px 0 0;
  }
}

.alignTitleLeft .itemInner.xSmall {
  background-color: #fff;
  max-width: 100%;
  height: 110px;
  margin: 0 9px;
  padding: 9px 0;
}

.swiperItem .itemInner.xSmall {
  justify-content: center;
  align-items: center;
  min-width: 90px;
  max-width: none;
  height: 120px;
  margin: 0;
  display: flex;
}

.itemInner.small {
  height: 180px;
  margin: 9px 27px 0 0;
}

@media (width <= 768px) {
  .itemInner.small {
    height: 180px;
    margin: 9px 27px 0 0;
  }
}

.itemInner.large {
  max-width: 346px;
  height: 205px;
  margin: 0 18px;
  padding: 27px 0 18px;
}

@media (width <= 768px) {
  .itemInner.large {
    max-width: 120px;
    height: 120px;
    margin: 0 45px 0 0;
  }
}

.imageLink {
  display: block;
}

.lazy, .imageLink {
  width: 100%;
  height: 100%;
  margin-left: 1px;
}

.swiperItem .lazy.xSmall, .swiperItem .imageLink.xSmall {
  max-width: 90px;
  height: 90px;
}

.lazy {
  justify-content: center;
  align-items: center;
  display: flex;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.heartButton {
  position: absolute;
}

.heartButton.xSmall {
  top: -5px;
  right: -27px;
}

.alignTitleLeft .heartButton.xSmall, .swiperItem .heartButton.xSmall {
  top: 5px;
  right: -5px;
}

.heartButton.small {
  top: -5px;
  right: -27px;
}

.heartButton.large {
  top: 9px;
  right: -9px;
}

.heart {
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  display: flex;
}

.swiperItem .heart.xSmall {
  width: 20px;
  height: 20px;
}

.loading {
  justify-content: center;
  align-items: center;
  display: flex;
}

.loading.xSmall {
  height: 157px;
}

@media (width <= 768px) {
  .loading.xSmall {
    height: 120px;
  }
}

.loading.small {
  height: 189px;
}

@media (width <= 768px) {
  .loading.small {
    height: 189px;
  }
}

.loading.large {
  height: 205px;
}

@media (width <= 768px) {
  .loading.large {
    height: 120px;
  }
}
