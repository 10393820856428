:local .wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
:local .listWrapper {
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 0;
  overflow: hidden;
}
:local .list {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transform: translate3d(0, 0, 0);
  list-style: none;
}
.slide :local .list {
  transition: transform 300ms ease-out 100ms;
}
:local .list.isVerticallyAligned {
  flex-direction: column;
}
:local .item {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
:local .button {
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 150ms linear;
  color: #888;
  fill: #888;
}
:local .button:hover {
  color: #666;
}
:local .arrow {
  width: 40px;
}
:local .arrowSVG {
  width: 28px;
  height: 48px;
}
:local .arrowLeft {
  margin-right: 4%;
}
:local .arrowRight {
  margin-left: 4%;
}
:local .dotsWrapper {
  margin-top: 9px;
  text-align: center;
}