.priceWrapper {
  width: max-content;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.priceWrapper.pillWrapper {
  white-space: nowrap;
  background-color: #f3f3f3;
  border-radius: 16px;
  padding: 4px 9px 0;
}

.paddle {
  width: 10px;
  height: 12px;
  margin-right: 5px;
}
