.dotsWrapper {
  text-align: center;
  margin-top: 27px;
}

.spinContainer {
  height: 205.19px;
  margin: 0 auto 205.19px;
}

@media (width <= 1024px) {
  .spinContainer {
    height: 190.19px;
    margin-bottom: 190.19px;
  }
}

@media (width <= 768px) {
  .spinContainer {
    height: 186.19px;
    margin-bottom: 186.19px;
  }
}

@media (width <= 568px) {
  .spinContainer {
    height: 212.69px;
    margin-bottom: 212.69px;
  }
}

.list {
  align-items: stretch;
}

.carouselItem {
  height: auto;
}
