.container {
  min-height: 300px;
}

.gridImage {
  grid-area: image;
}

.playIcon {
  width: 45px;
}