/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.link {
  display: block;
}

.item {
  margin-bottom: 36px;
}

.fullWidth {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
}
@media (min-width: 569px) and (max-width: 1024px) {
  .fullWidth {
    position: relative;
    left: calc(-1 * var(--sassy-layout-offset));
    min-width: 1024px;
    width: 100vw;
  }
}

.image {
  display: block;
  max-height: 100%;
  width: 100%;
}
@media (min-width: 769px) {
  .image.isFullBleed {
    position: relative;
    width: 100vw;
    left: calc((100vw - 100%) / -2);
    max-height: 400px;
    object-fit: cover;
  }
}

.imageCopy {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

@media (max-width: 568px) {
  .tileImageOverlay {
    position: relative;
    width: 100vw;
    left: calc((100vw - 100%) / -2);
    max-width: none;
  }
}

.tileImage {
  width: 100%;
}