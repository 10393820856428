:local .dotsWrapper {
  height: 16px;
  margin: auto;
  overflow: hidden;
}

:local .wrapper {
  align-items: center;
  display: flex;
  transform: translate3d(0, 0, 0);
}

:local .dot {
  visibility: hidden;
  cursor: pointer;
  align-items: center;
  min-width: 9px;
  min-height: 9px;
  margin: 0 4.5px;
  display: flex;
}

:local .dot.isVisible {
  visibility: visible;
}

:local .dot:after {
  content: "";
  background: #ddd;
  border-radius: 50%;
  min-width: 9px;
  min-height: 9px;
  display: block;
}

:local .dot.small:after {
  min-width: 7px;
  min-height: 7px;
}

:local .dot.tiny:after {
  min-width: 5px;
  min-height: 5px;
}

:local .dot:hover:after {
  background: #a48e45;
}

:local .dot.isCurrent:after {
  background: #a48e45;
}

:local .dot.isCurrentDark:after {
  background: #222;
}
