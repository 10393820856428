@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.wrapper.noneHorizontal {
  margin: 0 -.5px;
}

.wrapper.noneVertical {
  margin: -.5px 0;
}

.wrapper.xSmallHorizontal {
  margin: 0 -4.5px;
}

.wrapper.xSmallVertical {
  margin: -4.5px 0;
}

.wrapper.smallHorizontal {
  margin: 0 -9px;
}

.wrapper.smallVertical {
  margin: -9px 0;
}

.wrapper.mediumHorizontal {
  margin: 0 -13.5px;
}

.wrapper.mediumVertical {
  margin: -13.5px 0;
}

.wrapper.largeHorizontal {
  margin: 0 -18px;
}

.wrapper.largeVertical {
  margin: -18px 0;
}

.wrapper.noneHorizontalScrollPadding, .wrapper.noneVerticalScrollPadding {
  padding: 0;
  scroll-padding: 0;
}

.wrapper.smallHorizontalScrollPadding {
  padding: 0 18px;
  scroll-padding: 18px;
}

.wrapper.smallVerticalScrollPadding {
  padding: 18px 0;
  scroll-padding: 18px;
}

.wrapper.largeHorizontalScrollPadding {
  padding: 0 36px;
  scroll-padding: 36px;
}

.wrapper.largeVerticalScrollPadding {
  padding: 36px 0;
  scroll-padding: 36px;
}

.arrowSpacing {
  padding-left: 71px;
  padding-right: 71px;
}

.item.noneHorizontal {
  padding: 0 .5px;
}

.item.noneVertical {
  padding: .5px 0;
}

.item.xSmallHorizontal {
  padding: 0 4.5px;
}

.item.xSmallVertical {
  padding: 4.5px 0;
}

.item.smallHorizontal {
  padding: 0 9px;
}

.item.smallVertical {
  padding: 9px 0;
}

.item.mediumHorizontal {
  padding: 0 13.5px;
}

.item.mediumVertical {
  padding: 13.5px 0;
}

.item.largeHorizontal {
  padding: 0 18px;
}

.item.largeVertical {
  padding: 18px 0;
}

.shimmer {
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50% 51%, #ddd 100%);
  background-size: 400% 400%;
  animation-name: shimmerLoadingBackground;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  transform: translateZ(0);
}
