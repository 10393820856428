.pinchZoomContainer {
  touch-action: pan-x pan-y;
}

.zoomImageWrapper:global(.transition) {
  pointer-events: none;
  transition: transform .25s linear;
}

.zoomImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.spinner {
  top: 45%;
}

.zoomOut {
  background-color: #fff;
  width: 40px;
  height: 40px;
}

.zoomOutIcon {
  width: 20px;
  height: 20px;
}
