.wrapper {
  margin-top: 36px;
}

.isSwiperCarousel {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

.title {
  letter-spacing: -.5px;
  margin: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width <= 568px) {
  .title {
    letter-spacing: -.5px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

.header {
  text-align: center;
  margin-bottom: 45px;
}

.isSwiperCarousel .header {
  margin-bottom: 18px;
}
