.itemWrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.swiperItem .itemWrapper {
  background-color: #fff;
  width: 100%;
}

.itemInner {
  width: 100%;
  max-width: 200px;
  height: 250px;
  margin: 9px 27px 0 0;
  position: relative;
}

@media (width <= 568px) {
  .itemInner {
    max-width: 160px;
    height: 200px;
    margin: 0;
  }
}

.heartButton {
  position: absolute;
  top: -5px;
  right: -30px;
}

.swiperItem .heartButton {
  top: 5px;
  right: 5px;
}

.heart {
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  display: flex;
}

.swiperItem .heart {
  width: 20px;
  height: 20px;
}
