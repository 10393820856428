.link {
  display: block;
}

.item {
  margin-bottom: 36px;
}

.fullWidth {
  justify-content: center;
  width: 100vw;
  display: flex;
  position: relative;
  left: calc(-50vw + 50%);
  overflow: hidden;
}

@media (width >= 569px) and (width <= 1024px) {
  .fullWidth {
    left: calc(-1 * var(--sassy-layout-offset));
    width: 100vw;
    min-width: 1024px;
    position: relative;
  }
}

.image {
  width: 100%;
  max-height: 100%;
  display: block;
}

@media (width >= 769px) {
  .image.isFullBleed {
    object-fit: cover;
    width: 100vw;
    max-height: 400px;
    position: relative;
    left: calc(-50vw + 50%);
  }
}

.imageCopy {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@media (width <= 568px) {
  .tileImageOverlay {
    width: 100vw;
    max-width: none;
    position: relative;
    left: calc(-50vw + 50%);
  }
}

.tileImage {
  width: 100%;
}
