.headerContainer {
  text-align: center;
}
.headerContainer.large {
  margin: 36px;
}
.headerContainer.larger {
  margin: 45px;
}
@media (max-width: 768px) {
  .headerContainer.larger {
    margin: 27px;
  }
}

@media (max-width: 568px) {
  .titleWrapper {
    margin-top: 0;
  }
}

.title.large {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}
@media (max-width: 768px) {
  .title.large {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: -0.5px;
  }
}
.title.larger {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  color: #222;
}
@media (max-width: 768px) {
  .title.larger {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: -0.5px;
  }
}

.subtitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 300;
  margin-top: 9px;
}

.viewMore {
  display: flex;
  justify-content: center;
}
.viewMore.large {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}
.viewMore.larger {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin-top: 5px;
  color: #222;
}
@media (max-width: 768px) {
  .viewMore.larger {
    margin-top: 0;
  }
}