@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.item {
  text-align: center;
  max-height: 360px;
  padding: 9px;
}

.link {
  background: #f3f3f3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 21px 0;
  line-height: 1;
  text-decoration: none;
  display: flex;
  position: relative;
}

.title {
  letter-spacing: -.5px;
  color: #222;
  padding-bottom: 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width >= 1025px) {
  .title {
    padding-bottom: 18px;
  }
}

.imageContainer {
  z-index: 0;
  width: 100px;
  height: 100px;
}

@media (width >= 569px) {
  .imageContainer {
    width: 120px;
    height: 120px;
  }
}

@media (width >= 1025px) {
  .imageContainer {
    width: 150px;
    height: 150px;
  }
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.cta {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 12px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

@media (width >= 1025px) {
  .cta {
    padding-top: 18px;
  }
}
