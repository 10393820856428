@keyframes heart-beat {
  0%, 100% {
    transform: scale(1);
  }

  33% {
    transform: scale(.8);
  }

  66% {
    transform: scale(1.2);
  }
}

.likeIcon {
  fill: #0000;
  stroke-width: 12px;
  stroke: #c2a661;
  transition: fill .2s;
}

.likeIcon.showHeartBeat {
  animation: .7s ease-in-out heart-beat;
}

.likeIcon.isFilled {
  fill: #c2a661;
}

.likeIcon.dark {
  stroke: #222;
}

.likeIcon.dark.isFilled {
  fill: #222;
}

.likeIcon.light {
  stroke: #fff;
}

.likeIcon.light.isFilled {
  fill: #fff;
}
