.container {
  flex-wrap: wrap;
  min-height: 500px;
  display: flex;
}

.info {
  flex-basis: 100%;
  font-size: 16px;
  font-weight: 300;
}

.info details {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.info details > summary {
  list-style: none;
}

.info details > summary::-webkit-details-marker {
  display: none;
}

.header {
  border-bottom: 1px solid #ddd;
  margin: 24px 0;
  padding-bottom: 12px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
}

.imageWrap {
  flex-basis: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.image {
  width: 100%;
  max-width: 320px;
}

@media (width >= 769px) {
  .info {
    flex-basis: 60%;
  }

  .header {
    letter-spacing: -.5px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 32px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
  }

  .imageWrap {
    flex-basis: 40%;
    align-items: flex-end;
  }

  .image {
    max-width: 400px;
  }
}
