/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  margin-top: 36px;
}

.isSwiperCarousel {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 0;
}
@media (max-width: 568px) {
  .title {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.5px;
  }
}

.header {
  text-align: center;
  margin-bottom: 45px;
}
.isSwiperCarousel .header {
  margin-bottom: 18px;
}