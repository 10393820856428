/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.container {
  margin: 45px auto 0 auto;
  padding: 36px 0 27px 0;
  background: #f6f3eb;
  text-align: center;
}

.titleWrapper {
  margin-bottom: 36px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin-top: 0;
  margin-bottom: 9px;
}

.carouselWrapper {
  overflow: hidden;
}

.item {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 9px;
  overflow: hidden;
}

.innerItem {
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heartWrapper {
  width: 23px;
}

.itemImage {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.arrow {
  width: 54px;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.carouselWrapper:not(.showDotsAndArrows) .wrapper {
  padding: 0 54px;
}

.arrowLeft {
  margin-right: 0;
}

.arrowRight {
  margin-left: 0;
}

.dotsWrapper {
  margin-top: 27px;
  text-align: center;
}

.isMobile.container {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
}
.isMobile .carouselWrapper {
  overflow: scroll;
}
.isMobile .carouselWrapper:not(.showDotsAndArrows) .wrapper {
  padding: 0 27px;
}