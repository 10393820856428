.eyeBrow {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 27px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

@media (width <= 768px) {
  .eyeBrow {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 12px;
    font-weight: 300;
  }
}

.text {
  padding: 18px 0;
}

.text.textAlignCenter {
  text-align: center;
}

.text.contentColorWhite {
  color: #fff;
}

.header {
  margin-bottom: 18px;
}

@media (width >= 1025px) {
  .header {
    letter-spacing: -2px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 80px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.1;
  }

  .header em {
    letter-spacing: 0;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 80px;
    font-style: italic;
    font-weight: normal;
    line-height: 1.1;
  }
}

@media (width >= 569px) and (width <= 1024px) {
  .header {
    letter-spacing: -1px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 52px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }

  .header em {
    letter-spacing: 0;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 52px;
    font-style: italic;
    font-weight: normal;
    line-height: 1.4;
  }
}

@media (width <= 568px) {
  .header {
    letter-spacing: -.5px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 32px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }

  .header em {
    letter-spacing: 0;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 32px;
    font-style: italic;
    font-weight: normal;
    line-height: 1.4;
  }
}

.header, .header em {
  line-height: 1 !important;
}

.dek {
  letter-spacing: -.5px;
  margin-bottom: 27px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width <= 1024px) {
  .dek {
    letter-spacing: -.5px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

.cta {
  color: #fff;
  background-color: #222;
}

.cta:hover {
  color: #fff !important;
  background-color: #222 !important;
}

.cta.outline {
  color: #222;
  background-color: #0000;
  border-color: #222;
}

.cta.outline:hover {
  background-color: #0000 !important;
}

.cta.outline.contentColorWhite {
  color: #fff;
  border-color: #fff;
}
