.wrapper {
  margin-bottom: 36px;
}

.player {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframeWrapper {
  padding: 56.25% 0 0 0;
  position: relative;
}

.leftBlock {
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  padding: 27px;
  background: #f3f3f3;
  height: 100%;
}
@media (max-width: 768px) {
  .leftBlock {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .rightBlock {
    margin-bottom: 18px;
  }
}

.profileImageWrapper {
  min-width: 160px;
  min-height: 160px;
  margin-right: 27px;
}
@media (max-width: 1024px) {
  .profileImageWrapper {
    min-width: 120px;
    min-height: 120px;
  }
}
@media (max-width: 768px) {
  .profileImageWrapper {
    margin-right: 0;
    margin-bottom: 36px;
  }
}

.profileImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.collectionImageWrapper {
  position: relative;
  width: 100%;
  height: 395px;
  overflow: hidden;
}
@media (max-width: 568px) {
  .collectionImageWrapper {
    height: 250px;
  }
}

.collectionImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.linkText {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 18px 0;
  text-align: center;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.productsRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}