@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.shimmer {
  animation-name: shimmerLoadingBackground;
  animation-direction: alternate;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50%, #f3f3f3 51%, #ddd 100%);
  background-size: 400% 400%;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.placeholder {
  --max-width: var(--prop-max-width, var(--lazy-media-max-width, var(--lazy-media-max-size)));
  --max-height: var(--prop-max-height, var(--lazy-media-max-height, var(--lazy-media-max-size)));
  object-fit: scale-down;
  --responsive-width: (100vw - 36px);
  --adjusted-width: min(var(--media-width) * 1px, var(--max-width), var(--responsive-width));
  --adjusted-height: min(var(--media-height) * 1px, var(--max-height));
  --scaled-by-width: calc(var(--adjusted-width) / var(--media-width));
  --scaled-by-height: calc(var(--adjusted-height) / var(--media-height));
  --chosen-scale: min(var(--scaled-by-width), var(--scaled-by-height));
  width: calc(var(--chosen-scale) * var(--media-width));
  height: calc(var(--chosen-scale) * var(--media-height));
}