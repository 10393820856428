:export {
  FADE_TIMEOUT: 225;
}

.important.container {
  pointer-events: none;
  z-index: 10;
  background: linear-gradient(0deg, #fff6, #fff6), var(--poster, url("//"));
  opacity: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .225s cubic-bezier(.4, 0, 1, 1);
  display: flex;
  position: absolute;
  inset: 0;
}

.important.container.fade {
  opacity: 0;
}

.important.container svg {
  width: 40px;
}
