.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
  font-size: 1em;
  margin: 0;
  text-align: left;
  line-height: 1.3;
  display: block;
}
@supports (-webkit-line-clamp: 2) {
  .title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
  .title {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}

.link {
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  max-height: 2.6em;
  width: 100%;
  color: currentColor;
}
.link.imageXLarge {
  font-size: 20px;
}

.artOneLine {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.artOneLine.noCreator {
  margin-top: 18px;
}

.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}