.text {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.image {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.imageOverlay {
  position: relative;
}

.link {
  text-decoration: none;
  display: block;
}

.header {
  letter-spacing: -.5px;
  margin-top: 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  color: initial !important;
}

@media (width <= 568px) {
  .header {
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
  }
}

.description {
  margin-top: 9px;
  color: initial !important;
}

.cta {
  margin-top: 9px;
  text-decoration: underline;
}
