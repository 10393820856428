.text {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.image {
  display: block;
  max-height: 100%;
  max-width: 100%;
}

.imageOverlay {
  position: relative;
}

.link {
  text-decoration: none;
  display: block;
}

.header {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  color: initial !important;
  margin-top: 18px;
}
@media (max-width: 568px) {
  .header {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
}

.description {
  color: initial !important;
  margin-top: 9px;
}

.cta {
  margin-top: 9px;
  text-decoration: underline;
}