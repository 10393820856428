@media (width <= 568px) {
  .wrapper {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }
}

@media (width >= 1025px) {
  .wrapper {
    max-width: 1080px;
    margin: 0 auto;
  }
}

.subHeader {
  letter-spacing: -.5px;
  text-align: center;
  margin: 0;
  padding: 36px 0 27px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width >= 769px) {
  .subHeader {
    padding-top: 54px;
  }
}

.swiperList {
  align-items: flex-start;
}
