/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.contentContainer {
  margin: auto;
  opacity: 0;
  position: relative;
  max-width: 1440px;
  align-items: center;
  height: 100%;
  --grid-gap: 36px;
  --grid-gutter: calc(var(--sassy-layout-offset) - var(--grid-gap));
  display: grid;
  grid-gap: 0 var(--grid-gap);
  gap: 0 var(--grid-gap);
  grid-template-rows: [text image] 1fr;
  grid-template-columns: [gutter-left] var(--grid-gutter) [text-start] 1fr [image-start text-end] 1fr [] var(--grid-gutter) [gutter-right image-end];
}
@media (max-width: 1024px) {
  .contentContainer {
    --grid-gap: 18px;
  }
}
@media (max-width: 568px) {
  .contentContainer {
    grid-template-rows: [image] 1fr [text] auto;
    grid-template-columns: [gutter-left image-start] var(--grid-gutter) [text-start] 1fr [text-end] var(--grid-gutter) [gutter-right image-end];
  }
  .contentContainer.fullWidth {
    grid-template-rows: [image-start] 1fr [text] 1fr [image-end];
  }
}
.contentContainer.reverse {
  direction: rtl;
}
.contentContainer.reverse > * {
  direction: initial;
}
.contentContainer.visible {
  opacity: 1;
}
@media (min-width: 569px) {
  .contentContainer.border {
    border-top: thin solid #ddd;
    border-bottom: thin solid #ddd;
    padding: 27px 0;
  }
}
@media (min-width: 1025px) {
  .contentContainer.border {
    padding: 36px 0;
  }
}

.textContainer {
  z-index: 10;
  grid-area: text;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
}
.image.fullWidth {
  height: 100%;
  object-fit: cover;
}

.imageContainer {
  grid-area: image;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: 1;
}
.imageContainer.fullWidth {
  height: 100%;
  grid-column: gutter-left/gutter-right;
}

.overlayLink {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.overlayLink.right {
  right: 50%;
}
.overlayLink.left {
  left: 50%;
}
.overlayLink.top {
  top: 50%;
}