:local .wrapper {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

:local .listWrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
}

:local .list {
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.slide :local .list {
  transition: transform .3s ease-out .1s;
}

:local .list.isVerticallyAligned {
  flex-direction: column;
}

:local .item {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

:local .button {
  cursor: pointer;
  color: #888;
  fill: #888;
  background: none;
  border: none;
  width: 100%;
  transition: color .15s linear;
}

:local .button:hover {
  color: #666;
}

:local .arrow {
  width: 40px;
}

:local .arrowSVG {
  width: 28px;
  height: 48px;
}

:local .arrowLeft {
  margin-right: 4%;
}

:local .arrowRight {
  margin-left: 4%;
}

:local .dotsWrapper {
  text-align: center;
  margin-top: 9px;
}
