.carouselWrapper {
  width: unset;
  margin: -4px;
}

@media (width <= 768px) {
  .fullBleedListWrapper {
    align-items: flex-start !important;
  }
}

.listWrapper {
  align-items: stretch;
}

.carouselItem {
  height: auto;
  padding: 4px;
}

.moduleContainer {
  padding-top: 36px;
}

.moduleContainer.isFullBleed {
  padding-top: 0;
}

@media (width >= 769px) {
  .moduleContainer.isFullBleed {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    overflow: hidden;
  }
}

.moduleContainer.fullWidth {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  overflow: hidden;
}

@media (width >= 569px) and (width <= 1024px) {
  .moduleContainer.fullWidth {
    left: calc(-1 * var(--sassy-layout-offset));
    width: 100vw;
    min-width: 1024px;
    position: relative;
  }
}
