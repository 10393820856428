.header {
  margin-bottom: 9px;
}

.isSwiperCarousel .header {
  margin-bottom: 18px;
  margin-left: 18px;
}

.alignTitleLeft .header {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0;
  display: flex;
}

.centerTitle .header {
  text-align: center;
  margin-bottom: 45px;
}

.title {
  letter-spacing: -.5px;
  margin: 0 0 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width <= 768px) {
  .title {
    letter-spacing: -.5px;
    margin: 0;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

.viewMore {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.alignTitleLeft .viewMore {
  margin-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: block;
}

.carousel {
  width: 100%;
  min-width: 0;
}

.alignTitleLeft .carousel {
  max-width: 1087px;
  margin-left: auto;
}
