:local .setOffset {
  width: calc(100% - 90px);
}

@media (width <= 1024px) {
  :local .setOffset {
    width: calc(100% - 72px);
  }
}

@media (width <= 568px) {
  :local .setOffset {
    width: calc(100% - 36px);
  }
}

:local .viewportContainer {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}
