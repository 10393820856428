.imageCopy {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.bannerImgContainer {
  opacity: 0;
  display: block;
}

.bannerImgContainer.isVisible {
  opacity: 1;
}

.bannerImgContainer, .bannerImg {
  width: 100%;
}

@media (width >= 769px) {
  .bannerImg.isFullBleed {
    object-fit: cover;
    max-height: 400px;
  }
}
