.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0;
  padding: 18px 0 9px;
  text-align: center;
  width: 100%;
}

.shopNow {
  margin: auto;
  padding-bottom: 18px;
}

.products {
  position: relative;
  padding: 0 18px 27px;
  min-height: 222px;
}

.productTile {
  overflow: hidden;
}

.category {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 27px auto;
  padding: 0 18px;
  width: 100%;
}