.header {
  margin-bottom: 9px;
}
.isSwiperCarousel .header {
  margin-bottom: 18px;
  margin-left: 18px;
}
.alignTitleLeft .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0;
}
.centerTitle .header {
  text-align: center;
  margin-bottom: 45px;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 0 0 9px;
}
@media (max-width: 768px) {
  .title {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.5px;
    margin: 0;
  }
}

.viewMore {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}
.alignTitleLeft .viewMore {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: block;
  margin-top: 9px;
}

.carousel {
  width: 100%;
  min-width: 0;
}
.alignTitleLeft .carousel {
  max-width: 1087px;
  margin-left: auto;
}