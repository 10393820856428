.wrapper {
  justify-content: center;
  align-items: center;
  gap: 27px;
  width: 100%;
  display: flex;
}

.wrapper.horizontal {
  flex-direction: row;
}

.wrapper.vertical {
  flex-direction: column;
  height: 100%;
}
