.dot {
  display: inline-block;
  margin: 0 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;
}
.dot:hover {
  background-color: #a48e45;
}
@media (hover: none) {
  .dot:hover {
    background-color: #ddd;
  }
}
.dot.activeDot {
  background-color: #a48e45;
}