@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.wrapper.noneHorizontal {
  margin: 0 calc(-1px / 2);
}
.wrapper.noneVertical {
  margin: calc(-1px / 2) 0;
}
.wrapper.xSmallHorizontal {
  margin: 0 calc(-9px / 2);
}
.wrapper.xSmallVertical {
  margin: calc(-9px / 2) 0;
}
.wrapper.smallHorizontal {
  margin: 0 calc(-18px / 2);
}
.wrapper.smallVertical {
  margin: calc(-18px / 2) 0;
}
.wrapper.mediumHorizontal {
  margin: 0 calc(-27px / 2);
}
.wrapper.mediumVertical {
  margin: calc(-27px / 2) 0;
}
.wrapper.largeHorizontal {
  margin: 0 calc(-36px / 2);
}
.wrapper.largeVertical {
  margin: calc(-36px / 2) 0;
}
.wrapper.noneHorizontalScrollPadding {
  scroll-padding: 0;
  padding: 0 0;
}
.wrapper.noneVerticalScrollPadding {
  scroll-padding: 0;
  padding: 0 0;
}
.wrapper.smallHorizontalScrollPadding {
  scroll-padding: 18px;
  padding: 0 18px;
}
.wrapper.smallVerticalScrollPadding {
  scroll-padding: 18px;
  padding: 18px 0;
}
.wrapper.largeHorizontalScrollPadding {
  scroll-padding: 36px;
  padding: 0 36px;
}
.wrapper.largeVerticalScrollPadding {
  scroll-padding: 36px;
  padding: 36px 0;
}

.arrowSpacing {
  padding-left: calc(44px + 27px);
  padding-right: calc(44px + 27px);
}

.item.noneHorizontal {
  padding: 0 calc(1px / 2);
}
.item.noneVertical {
  padding: calc(1px / 2) 0;
}
.item.xSmallHorizontal {
  padding: 0 calc(9px / 2);
}
.item.xSmallVertical {
  padding: calc(9px / 2) 0;
}
.item.smallHorizontal {
  padding: 0 calc(18px / 2);
}
.item.smallVertical {
  padding: calc(18px / 2) 0;
}
.item.mediumHorizontal {
  padding: 0 calc(27px / 2);
}
.item.mediumVertical {
  padding: calc(27px / 2) 0;
}
.item.largeHorizontal {
  padding: 0 calc(36px / 2);
}
.item.largeVertical {
  padding: calc(36px / 2) 0;
}

.shimmer {
  animation-name: shimmerLoadingBackground;
  animation-direction: alternate;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50%, #f3f3f3 51%, #ddd 100%);
  background-size: 400% 400%;
  backface-visibility: hidden;
  transform: translateZ(0);
}