@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.primaryItem {
  text-align: center;
}

.primaryLink {
  background: #f3f3f3;
  height: 100%;
  display: block;
}

.primaryImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (width >= 769px) {
  .productItems {
    margin: -9px 0;
  }
}

.fullHeight {
  height: 100%;
}
