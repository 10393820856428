.container {
  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
}

.info {
  font-size: 16px;
  font-weight: 300;
  flex-basis: 100%;
}

.info details {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.info details > summary {
  list-style: none;
}

.info details > summary::-webkit-details-marker {
  display: none;
}

.header {
  font-size: 18px;
  font-weight: 300;
  margin: 24px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
  line-height: 1.5;
}

.imageWrap {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-basis: 100%;
}

.image {
  width: 100%;
  max-width: 320px;
}

@media (min-width: 769px) {
  .info {
    flex-basis: 60%;
  }
  .header {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 1.4;
    letter-spacing: -0.5px;
    line-height: 1.25;
  }
  .imageWrap {
    flex-basis: 40%;
    align-items: flex-end;
  }
  .image {
    max-width: 400px;
  }
}