.priceWrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  bottom: 5px;
}
.priceWrapper.pillWrapper {
  padding: 4px 9px 0;
  border-radius: 16px;
  white-space: nowrap;
  background-color: #f3f3f3;
}

.paddle {
  height: 12px;
  width: 10px;
  margin-right: 5px;
}