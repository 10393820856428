:local .iconWrapper {
  background: none;
  border: none;
  height: 100%;
}

:local .heart {
  z-index: 10;
  background: none;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
}

:local .heart:hover {
  cursor: pointer;
}

:local .heartCount {
  margin-top: 5px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
}

:local .icon {
  z-index: 10;
  width: 26px;
  height: 26px;
  transition: fill .2s;
  display: block;
  position: relative;
}

:local .icon.hoverClass:hover {
  fill: #c2a661;
}

:local .icon.hoverClass:hover.dark {
  fill: #222;
}

:local .icon.hoverClass:hover.light {
  fill: #fff;
}

:local .icon.isStrokeWidthHeavy {
  stroke-width: 18px;
}

:local svg.icon:not(:root) {
  padding: 1px;
  overflow: visible;
}

:local .pulseIcon {
  fill: #222;
  opacity: .5;
  stroke: #222;
  stroke-width: 12px;
  width: 30px;
  height: 30px;
  animation: 1.5s .1s 3 both swell;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: visible !important;
}

:local .pulseIcon.isFilled {
  animation-iteration-count: 1;
}

:local .pulseIcon.isFilled.stopAnimation {
  stroke-width: 0;
  opacity: 0;
  animation-play-state: paused;
}

@keyframes swell {
  0% {
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%)scale(1.5);
  }
}
