.imageCopy {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.bannerImgContainer {
  display: block;
  opacity: 0;
}
.bannerImgContainer.isVisible {
  opacity: 1;
}

.bannerImgContainer,
.bannerImg {
  width: 100%;
}

@media (min-width: 769px) {
  .bannerImg.isFullBleed {
    max-height: 400px;
    object-fit: cover;
  }
}