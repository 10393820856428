@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.container .lazyPlaceholder, .container img {
  margin: auto;
  position: absolute;
  inset: 0;
}

.lazyPlaceholder {
  background: linear-gradient(to right, #f3f3f3 0%, #ddd 50% 51%, #f3f3f3 100%) 0 0 / 400% 400%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.lazyPlaceholder.shimmer {
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50% 51%, #ddd 100%);
  background-size: 400% 400%;
  animation-name: shimmerLoadingBackground;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  transform: translateZ(0);
}
