.link {
  outline-offset: -5px;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
@media (min-width: 569px) {
  .link {
    padding: 0 9px;
  }
}
@media (max-width: 568px) {
  .link {
    padding: 0 2px;
  }
}

.imageWrapper {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 569px) {
  .imageWrapper {
    height: 120px;
  }
}
@media (min-width: 769px) {
  .imageWrapper {
    height: 150px;
  }
}

.image {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.title {
  display: block;
  text-align: center;
  margin-top: 18px;
  min-height: 32px;
}
@media (min-width: 569px) {
  .title {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 300;
  }
}
@media (max-width: 568px) {
  .title {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    font-weight: 300;
  }
}
@media (min-width: 769px) {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}