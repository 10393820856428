/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.carouselWrapper {
  margin: -4px;
  width: unset;
}

@media (max-width: 768px) {
  .fullBleedListWrapper {
    align-items: flex-start !important;
  }
}

.listWrapper {
  align-items: stretch;
}

.carouselItem {
  padding: 4px;
  height: auto;
}

.moduleContainer {
  padding-top: 36px;
}
.moduleContainer.isFullBleed {
  padding-top: 0;
}
@media (min-width: 769px) {
  .moduleContainer.isFullBleed {
    position: relative;
    width: 100vw;
    left: calc((100vw - 100%) / -2);
    overflow: hidden;
  }
}
.moduleContainer.fullWidth {
  overflow: hidden;
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
}
@media (min-width: 569px) and (max-width: 1024px) {
  .moduleContainer.fullWidth {
    position: relative;
    left: calc(-1 * var(--sassy-layout-offset));
    min-width: 1024px;
    width: 100vw;
  }
}