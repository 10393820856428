.dotsWrapper {
  margin-top: 27px;
  text-align: center;
}

.spinContainer {
  margin: 0 auto;
  height: 205.19px;
  margin-bottom: 205.19px;
}
@media (max-width: 1024px) {
  .spinContainer {
    height: 190.19px;
    margin-bottom: 190.19px;
  }
}
@media (max-width: 768px) {
  .spinContainer {
    height: 186.19px;
    margin-bottom: 186.19px;
  }
}
@media (max-width: 568px) {
  .spinContainer {
    height: 212.69px;
    margin-bottom: 212.69px;
  }
}

.list {
  align-items: stretch;
}

.carouselItem {
  height: auto;
}