.contentContainer {
  opacity: 0;
  --grid-gap: 36px;
  --grid-gutter: calc(var(--sassy-layout-offset)  - var(--grid-gap));
  grid-gap: 0 var(--grid-gap);
  align-items: center;
  gap: 0 var(--grid-gap);
  grid-template-rows: [text image] 1fr;
  grid-template-columns: [gutter-left] var(--grid-gutter) [text-start] 1fr [image-start text-end] 1fr [] var(--grid-gutter) [gutter-right image-end];
  max-width: 1440px;
  height: 100%;
  margin: auto;
  display: grid;
  position: relative;
}

@media (width <= 1024px) {
  .contentContainer {
    --grid-gap: 18px;
  }
}

@media (width <= 568px) {
  .contentContainer {
    grid-template-rows: [image] 1fr[text] auto;
    grid-template-columns: [gutter-left image-start] var(--grid-gutter) [text-start] 1fr [text-end] var(--grid-gutter) [gutter-right image-end];
  }

  .contentContainer.fullWidth {
    grid-template-rows: [image-start] 1fr[text] 1fr[image-end];
  }
}

.contentContainer.reverse {
  direction: rtl;
}

.contentContainer.reverse > * {
  direction: initial;
}

.contentContainer.visible {
  opacity: 1;
}

@media (width >= 569px) {
  .contentContainer.border {
    border-top: thin solid #ddd;
    border-bottom: thin solid #ddd;
    padding: 27px 0;
  }
}

@media (width >= 1025px) {
  .contentContainer.border {
    padding: 36px 0;
  }
}

.textContainer {
  z-index: 10;
  flex-direction: column;
  grid-area: text;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image {
  width: 100%;
}

.image.fullWidth {
  object-fit: cover;
  height: 100%;
}

.imageContainer {
  opacity: 1;
  grid-area: image;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.imageContainer.fullWidth {
  grid-column: gutter-left / gutter-right;
  height: 100%;
}

.overlayLink {
  z-index: 10;
  position: absolute;
  inset: 0;
}

.overlayLink.right {
  right: 50%;
}

.overlayLink.left {
  left: 50%;
}

.overlayLink.top {
  top: 50%;
}
