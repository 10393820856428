.link {
  display: block;
}

.wrapper {
  width: 100%;
  margin-top: 36px;
}

.image {
  width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
}

@media (width >= 769px) {
  .image.isFullBleed {
    object-fit: cover;
    width: 100vw;
    max-height: 180px;
    position: relative;
    left: calc(-50vw + 50%);
  }
}

.imageCopy {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@media (width <= 768px) {
  .sectionWrapper {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }
}

.incentivesWrapper {
  grid-template-columns: 1fr 1fr 1fr;
}

@media (width <= 768px) {
  .incentivesWrapper {
    grid-template-columns: 1fr;
  }
}
