/**
 * TODO: Fix webpack. Without this var webpack doesn't add userType specificity classes in storybook for carousel component.
 * Because of this all imports that have a userType specific styles override carousel component styles.
 */
.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 27px;
}
.wrapper.horizontal {
  flex-direction: row;
}
.wrapper.vertical {
  flex-direction: column;
  height: 100%;
}