.link {
  text-decoration: none;
  display: block;
}

.item {
  color: #666;
  margin: 18px 0;
  font-size: 14px;
  font-weight: 300;
}

.image {
  width: 100%;
  height: 100%;
}

.title {
  margin-top: 18px;
}

@supports (-webkit-line-clamp: 3) {
  .title {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}
