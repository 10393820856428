@keyframes heart-beat {
  0%, 100% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.8);
  }
  66% {
    transform: scale(1.2);
  }
}
.likeIcon {
  fill: transparent;
  stroke-width: 12px;
  stroke: #c2a661;
  transition: fill 0.2s ease;
}
.likeIcon.showHeartBeat {
  animation: heart-beat 0.7s ease-in-out;
}
.likeIcon.isFilled {
  fill: #c2a661;
}
.likeIcon.dark {
  stroke: #222;
}
.likeIcon.dark.isFilled {
  fill: #222;
}
.likeIcon.light {
  stroke: #fff;
}
.likeIcon.light.isFilled {
  fill: #fff;
}