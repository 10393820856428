.container {
  text-align: center;
  background: #f6f3eb;
  margin: 45px auto 0;
  padding: 36px 0 27px;
}

.titleWrapper {
  margin-bottom: 36px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.title {
  letter-spacing: -.5px;
  margin-top: 0;
  margin-bottom: 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.carouselWrapper {
  overflow: hidden;
}

.item {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 9px;
  overflow: hidden;
}

.innerItem {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.heartWrapper {
  width: 23px;
}

.itemImage {
  object-fit: contain;
  width: 80%;
  height: 80%;
}

.arrow {
  width: 54px;
}

.wrapper {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.carouselWrapper:not(.showDotsAndArrows) .wrapper {
  padding: 0 54px;
}

.arrowLeft {
  margin-right: 0;
}

.arrowRight {
  margin-left: 0;
}

.dotsWrapper {
  text-align: center;
  margin-top: 27px;
}

.isMobile.container {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

.isMobile .carouselWrapper {
  overflow: scroll;
}

.isMobile .carouselWrapper:not(.showDotsAndArrows) .wrapper {
  padding: 0 27px;
}
