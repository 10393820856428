:local .setOffset {
  width: calc(100% - (2 * 45px));
}
@media (max-width: 1024px) {
  :local .setOffset {
    width: calc(100% - (2 * 36px));
  }
}
@media (max-width: 568px) {
  :local .setOffset {
    width: calc(100% - (2 * 18px));
  }
}
:local .viewportContainer {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
}