.link {
  text-decoration: none;
  display: block;
}

.item {
  font-size: 14px;
  font-weight: 300;
  margin: 18px 0;
  color: #666;
}

.image {
  width: 100%;
  height: 100%;
}

.title {
  margin-top: 18px;
}
@supports (-webkit-line-clamp: 3) {
  .title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }
  .title {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}