/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.itemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemWrapper.xSmall {
  width: 100%;
}
.itemWrapper.xSmall.swiperItem {
  background-color: #fff;
  padding: 0 9px;
}
.itemWrapper.small {
  width: 100%;
}

.itemInner {
  position: relative;
  width: 100%;
}
.itemInner.xSmall {
  margin: 9px 27px 0 0;
  height: 148px;
  max-width: 148px;
}
@media (max-width: 768px) {
  .itemInner.xSmall {
    margin: 0px 45px 0 0;
    height: 120px;
    max-width: 120px;
  }
}
.alignTitleLeft .itemInner.xSmall {
  margin: 0 9px;
  height: 110px;
  max-width: 100%;
  padding: 9px 0;
  background-color: #fff;
}
.swiperItem .itemInner.xSmall {
  margin: 0;
  height: 120px;
  min-width: 90px;
  max-width: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemInner.small {
  margin: 9px 27px 0 0;
  height: 180px;
}
@media (max-width: 768px) {
  .itemInner.small {
    margin: 9px 27px 0 0;
    height: 180px;
  }
}
.itemInner.large {
  height: 205px;
  max-width: 346px;
  margin: 0 18px;
  padding: 27px 0 18px 0;
}
@media (max-width: 768px) {
  .itemInner.large {
    margin: 0px 45px 0 0;
    height: 120px;
    max-width: 120px;
  }
}

.imageLink {
  display: block;
}

.lazy,
.imageLink {
  width: 100%;
  height: 100%;
  margin-left: 1px;
}
.swiperItem .lazy.xSmall,
.swiperItem .imageLink.xSmall {
  height: 90px;
  max-width: 90px;
}

.lazy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  max-height: 100%;
  max-width: 100%;
}

.heartButton {
  position: absolute;
}
.heartButton.xSmall {
  top: -5px;
  right: -27px;
}
.alignTitleLeft .heartButton.xSmall, .swiperItem .heartButton.xSmall {
  top: 5px;
  right: -5px;
}
.heartButton.small {
  top: -5px;
  right: -27px;
}
.heartButton.large {
  top: 9px;
  right: -9px;
}

.heart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
}
.swiperItem .heart.xSmall {
  width: 20px;
  height: 20px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading.xSmall {
  height: calc(148px + 9px);
}
@media (max-width: 768px) {
  .loading.xSmall {
    height: calc(120px + 0px);
  }
}
.loading.small {
  height: calc(180px + 9px);
}
@media (max-width: 768px) {
  .loading.small {
    height: calc(180px + 9px);
  }
}
.loading.large {
  height: 205px;
}
@media (max-width: 768px) {
  .loading.large {
    height: calc(120px + 0px);
  }
}