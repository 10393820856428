.title {
  text-align: left;
  margin: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 1em;
  font-weight: 300;
  line-height: 1.3;
  display: block;
}

@supports (-webkit-line-clamp: 2) {
  .title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.link {
  color: currentColor;
  width: 100%;
  max-height: 2.6em;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.link.imageXLarge {
  font-size: 20px;
}

.artOneLine {
  white-space: nowrap;
  flex-wrap: nowrap;
  display: flex;
}

.artOneLine.noCreator {
  margin-top: 18px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
