:local .iconWrapper {
  background: none;
  border: none;
  height: 100%;
}
:local .heart {
  z-index: 10;
  background: none;
  border: none;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}
:local .heart:hover {
  cursor: pointer;
}
:local .heartCount {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  margin-top: 5px;
}
:local .icon {
  z-index: 10;
  position: relative;
  display: block;
  height: 26px;
  width: 26px;
  transition: fill 0.2s ease;
}
:local .icon.hoverClass:hover {
  fill: #c2a661;
}
:local .icon.hoverClass:hover.dark {
  fill: #222;
}
:local .icon.hoverClass:hover.light {
  fill: #fff;
}
:local .icon.isStrokeWidthHeavy {
  stroke-width: 18px;
}
:local svg.icon:not(:root) {
  overflow: visible;
  padding: 1px;
}
:local .pulseIcon {
  position: absolute;
  fill: #222;
  opacity: 0.5;
  stroke: #222;
  stroke-width: 12px;
  overflow: visible !important;
  height: 30px;
  width: 30px;
  top: 50%;
  left: 50%;
  animation: swell 1.5s ease 0.1s 3 normal both;
}
:local .pulseIcon.isFilled {
  animation-iteration-count: 1;
}
:local .pulseIcon.isFilled.stopAnimation {
  animation-play-state: paused;
  stroke-width: 0;
  opacity: 0;
}
@keyframes swell {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}