:local .moreOptionsMultisku circle:first-child {
  fill: #436b93;
}

:local .moreOptionsMultisku circle:nth-child(2) {
  fill: #c00;
}

:local .moreOptionsMultisku circle:nth-child(3) {
  fill: #c2a661;
}
