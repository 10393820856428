.link {
  outline-offset: -5px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: block;
}

@media (width >= 569px) {
  .link {
    padding: 0 9px;
  }
}

@media (width <= 568px) {
  .link {
    padding: 0 2px;
  }
}

.imageWrapper {
  justify-content: center;
  align-items: center;
  height: 110px;
  display: flex;
}

@media (width >= 569px) {
  .imageWrapper {
    height: 120px;
  }
}

@media (width >= 769px) {
  .imageWrapper {
    height: 150px;
  }
}

.image {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.title {
  text-align: center;
  min-height: 32px;
  margin-top: 18px;
  display: block;
}

@media (width >= 569px) {
  .title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 14px;
    font-weight: 300;
  }
}

@media (width <= 568px) {
  .title {
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 14px;
    font-weight: 300;
  }
}

@media (width >= 769px) {
  .title {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
