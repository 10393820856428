:local .wrapper {
  overflow: hidden;
}

:local .relative {
  position: relative;
}

:local .list {
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: visible;
}

:local .list.itemsTopAlign {
  align-items: flex-start;
}

:local .item {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

:local .arrowWrapper {
  z-index: 10;
  background-color: #ffffffe6;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

:local .prevArrowWrapper {
  left: 9px;
}

:local .nextArrowWrapper {
  right: 9px;
}

:local .arrow {
  fill: #888;
  width: 24px;
  height: 24px;
}

:local .prevArrow {
  margin-right: 4px;
}

:local .nextArrow {
  margin-left: 4px;
}
