.wrapper {
  margin-bottom: 36px;
}

.player {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.iframeWrapper {
  padding: 56.25% 0 0;
  position: relative;
}

.leftBlock {
  background: #f3f3f3;
  align-items: center;
  height: 100%;
  padding: 27px;
  font-size: 16px;
  font-weight: 300;
  display: flex;
}

@media (width <= 768px) {
  .leftBlock {
    text-align: center;
    flex-direction: column;
  }

  .rightBlock {
    margin-bottom: 18px;
  }
}

.profileImageWrapper {
  min-width: 160px;
  min-height: 160px;
  margin-right: 27px;
}

@media (width <= 1024px) {
  .profileImageWrapper {
    min-width: 120px;
    min-height: 120px;
  }
}

@media (width <= 768px) {
  .profileImageWrapper {
    margin-bottom: 36px;
    margin-right: 0;
  }
}

.profileImage {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.collectionImageWrapper {
  width: 100%;
  height: 395px;
  position: relative;
  overflow: hidden;
}

@media (width <= 568px) {
  .collectionImageWrapper {
    height: 250px;
  }
}

.collectionImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.linkText {
  letter-spacing: -.5px;
  text-align: center;
  margin: 18px 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.hidden {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.productsRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}
