/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.itemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.swiperItem .itemWrapper {
  width: 100%;
  background-color: #fff;
}

.itemInner {
  position: relative;
  width: 100%;
  margin: 9px 27px 0 0;
  height: 250px;
  max-width: 200px;
}
@media (max-width: 568px) {
  .itemInner {
    margin: 0;
    max-width: 160px;
    height: 200px;
  }
}

.heartButton {
  position: absolute;
  top: -5px;
  right: -30px;
}
.swiperItem .heartButton {
  top: 5px;
  right: 5px;
}

.heart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
}
.swiperItem .heart {
  width: 20px;
  height: 20px;
}