@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
:local .animationRunning {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease-in-out;
}
:local .background {
  background: linear-gradient(to right, #f4f4f4 0%, #e2e2e2 50%, #e2e2e2 51%, #f4f4f4 100%);
  background-size: 800px 100px;
}
:local .lazyLoadWrapper {
  position: relative;
  top: 0;
  left: 0;
}
:local .placeholderHidden {
  visibility: hidden;
  opacity: 0;
}
:local .placeholderPositioning {
  position: absolute;
  z-index: 2;
}
:local .placeholderTransition {
  transition: visibility 0.15s ease-out, opacity 0.15s ease-out;
}
:local .placeholder {
  height: 100%;
  width: 100%;
}
:local .child {
  position: relative;
  z-index: 1;
}