/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.link {
  display: block;
}

.wrapper {
  width: 100%;
  margin-top: 36px;
}

.image {
  display: block;
  max-height: 100%;
  width: 100%;
  height: auto;
}
@media (min-width: 769px) {
  .image.isFullBleed {
    position: relative;
    width: 100vw;
    left: calc((100vw - 100%) / -2);
    max-height: 180px;
    object-fit: cover;
  }
}

.imageCopy {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .sectionWrapper {
    position: relative;
    width: 100vw;
    left: calc((100vw - 100%) / -2);
  }
}

.incentivesWrapper {
  grid-template-columns: 1fr 1fr 1fr;
}
@media (max-width: 768px) {
  .incentivesWrapper {
    grid-template-columns: 1fr;
  }
}