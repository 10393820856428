/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
@media (max-width: 568px) {
  .wrapper {
    position: relative;
    width: 100vw;
    left: calc((100vw - 100%) / -2);
  }
}
@media (min-width: 1025px) {
  .wrapper {
    max-width: 1080px;
    margin: 0 auto;
  }
}

.subHeader {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  text-align: center;
  margin: 0;
  padding: 36px 0 27px;
}
@media (min-width: 769px) {
  .subHeader {
    padding-top: 54px;
  }
}

.swiperList {
  align-items: flex-start;
}